<template>
  <el-card class="box-card">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item name="1">
        <template slot="title">
          <i class="header-icon el-icon-search"></i> 搜索
        </template>
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="搜索方式:">
            <el-input placeholder="请输入内容" v-model="queryForm.name" class="input-with-select" size="small">
              <el-select v-model="queryForm.state" slot="prepend" placeholder="请选择">
                <el-option label="订单号" value="1"></el-option>
                <el-option label="外部订单号" value="2"></el-option>
                <el-option label="收货人手机" value="3"></el-option>
                <el-option label="收货人地址" value="4"></el-option>
                <el-option label="商品名称" value="5"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="关注时间段:">
            <el-date-picker
                v-model="query_time"
                size="small"
                type="daterange"
                :picker-options="pickerOptions"
                @change="getTimeSection"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click="queryData" type="primary" plain size="small">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>

    <vab-query-form-left-panel  :span="24">
      <el-button class="button" @click="exportExcel" type="primary" size="small">导出</el-button>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        style="width: 100%">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="goods_obj.name"
          label="商品名">
      </el-table-column>
      <el-table-column
          align="center"
          prop="goods_obj.id"
          label="商品编号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="num"
          label="关注人数">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  props: [],
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '24小时',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近3天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近7天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '近30天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      activeName: '1',
      imgShow: true,
      query_time: [],
      selectRows: '',
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        state: '',
        name: '',
        start_date: '',
        end_date: '',
      },
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '2',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    //获取时间段
    getTimeSection(event) {
      console.log(event)
      if (event != null) {
        this.queryForm.start_date = event[0]
        this.queryForm.end_date = event[1]
      } else {
        this.queryForm.start_date = ''
        this.queryForm.end_date = ''
      }
    },
    // 导出
    async exportExcel() {
      const { data } = await exportOfflineMoney(this.queryForm)
      console.log(data)
      window.location.href = data[0]
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const { data } = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },
  }
}

</script>
<style>
  .el-input-group__prepend {
    width: 80px;
  }

  .button {
    margin-top: 20px;
  }

  .el-pagination {
    text-align: center;
    margin-top: 20px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-dropdown {
    margin-right: 10px;
  }

  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;
  }

  .el-row {
    margin-bottom: 15px;
  }
</style>
